.profile-summary {
	background-color: #212121;
	padding: 30px 20px;
	border-radius: 20px;
	border: 1px solid #121212;
}

.profile-summary .details {
	margin: 30px 0;
	color: var(--text-color);
	line-height: 1.8em;
	font-size: 0.9em;
}

.desktop .page-title {
	font-size: 1.2em;
	color: var(--heading-color);
	display: inline-block;
}

.mobile .page-title {
	font-size: 0.8em;
	color: var(--heading-color);
	display: inline-block;
}


.profile-summary .info {
	margin: 10px 0;
	line-height: 1.8em;
	font-size: 0.9em;
	color: #BBBBBB;
}

.btn-delete{
	background: #3d3d3d;
	padding: 8px 12px;
	border-radius: 15px;
	color: rgb(253, 2, 2);
	cursor: pointer;
	font-size: 1em;
	border: 1px solid rgb(253, 2, 2);
}
.btn-delete:hover {
	color: #181a1b;
	background-color: rgb(253, 2, 2);
}

.btn-cancel{
	background: #3d3d3d;
	padding: 8px 12px;
	border-radius: 15px;
	color: rgb(253, 2, 2);
	cursor: pointer;
	font-size: 1em;
	border: 1px solid rgb(253, 2, 2);
}

.btn-cancel-grey {
	margin: 0 0 0 20px;
	background: #3d3d3d;
	padding: 8px 12px;
	border-radius: 15px;
	color: black;
	cursor: pointer;
	font-size: 1em;
	border: 1px solid black;
}

.btn-cancel:hover {
	color: #fff;
	background-color: rgb(180, 179, 179);
}

.sidebar {
    width: 300px;
    min-width: 300px;
    background: var(--primary-color);
    min-height: max(100%, 100vh);
    box-sizing: border-box;
    position: relative;
    color: #fff;
}
.sidebar-content {
    position: fixed;
    width: inherit;
}
.sidebar .user {
    text-align: center;
	font-size: 1.5em;
	letter-spacing: 1px;
    padding: 40px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
	color: #3bdd92;
}
.sidebar .links {
    margin-top: 80px;
    margin-left: 20px;
}
.sidebar .links li {
    margin-top: 10px;
}
.sidebar .links a {
    display: flex;
    padding: 10px;
    text-decoration: none;
    width: 100%;
    color: #aaa;
    box-sizing: border-box;
}
.sidebar .links img {
    margin-right: 10px;
	filter: invert(40%);
}
.sidebar .links a.active {
    color: #fff;
    background: var(--bg-color);
    border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
}

.sidebar-mobile {
	margin: -80px 0 20px 0;
    background: var(--primary-color);
	resize:horizontal;
    box-sizing: border-box;
	border-radius: 20px 20px 20px 20px;
    color: #fff;
}
.sidebar-content-mobile {
    width: inherit;
}
.sidebar-mobile .user {
	font-size: 1em;
	letter-spacing: 1px;
    padding: 30px 30px;
	color: #3bdd92;
}
.sidebar-mobile .links {
    margin-left: 20px;
	margin-right: 20px;
	padding: 0 0 10px 0;
}

.sidebar-mobile .links a {
    display: flex;
    padding: 10px;
    text-decoration: none;
    width: 100%;
    color: #aaa;
    box-sizing: border-box;
}
.sidebar-mobile .links img {
    margin-right: 10px;
	margin-left: 10px;
	filter: invert(40%);
}
.sidebar-mobile .links a.active {
    color: #fff;
    background: var(--bg-color);
    border-radius: 20px 20px 20px 20px;
}
.sidebar-mobile .links .active img {
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap');
/* variables */

:root {
  --heading-color: #EEEEEE;
  --text-color: #EFEFEF;
  --primary-color: #212121;
  --highlight-color: #d13267;
  --bg-color: #181818;
}

/* base styles */
body {
  font-family: 'Open Sans', sans-serif !important;
  /*font-family: 'Inter', sans-serif;
  font-style: normal;*/
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}
.btn{
  background: #3d3d3d;
  padding: 8px 12px;
  border-radius: 15px;
  color: #3bdd92;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #3bdd92;
}

.btn-mobile {
  background: #3d3d3d;
  padding: 8px 8px;
  border-radius: 15px;
  color: #3bdd92;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #3bdd92;
}

.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

.btn-mobile:hover {
  color: #fff;
  background-color: var(--primary-color);
}


/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.mobile{display:none;}
.desktop{display:unset;}

@media only screen and (orientation: portrait) {
	.mobile{display:unset;}
	.desktop{display:none;}
}

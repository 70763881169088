/*.project-list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 20px;
  }
  .project-list a {
    background-color: #181A1B;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    border-left: 4px solid #3bdd92;
    text-decoration: none;
    color: inherit;
  }
  .project-list h4 {
    font-size: 0.9em;
    color: var(--heading-color);
  }

  .project-list p {
    color: var(--text-color);
    font-size: 0.9em;
  }
*/

.project-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 1200px;
  margin: 40px auto;
}

.project-list .card {
  background: #212121;
  color: #aaa;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  text-decoration: none;
}

.project-list .card h3 {
  color: #fff;
  margin-bottom: 6px;
  font-size: 2em !important;
  text-align: center;
  margin: 0 0 20px 0;
}
.project-list .card p {
  color: #aaa;
  font-size: 1.2em !important;
  text-align: center;
  margin: 0 0 50px;

}

.desktop .card h4 {
	text-decoration: none !important;
	text-align: center;
	color: #3bdd92;
	font-size: 1em;
	
}


.mobile .card h4 {
	text-decoration: none !important;
	text-align: center;
	color: #3bdd92;
	font-size: 1em;	
}
.project-list .card div {
  color: #fff;
  font-size: 0.7em;
  margin: 20px 0;
  line-height: 1.5em;
}
.project-list .card a {
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  font-size: 0.9em;
  width: 100px;
  text-align: center;
  width: 160px;
  height: 160px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}

.project-list .card .card-bc {
  background-image: 
  url("../assets/bc.png");
  background-size: "cover";
  background-repeat: no-repeat;
  background-position: center center;
  height: 200px;
}

.project-list-mobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  margin: 40px auto;
}

.project-list-mobile .card {
  background: #212121;
  color: #aaa;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  text-decoration: none !important;
}
.project-list-mobile .card h3 {
  color: #fff;
  margin-bottom: 6px;
  text-align: center;
  font-size: 1.5em;
}
.project-list-mobile .card p {
  color: #aaa;
  font-size: 1em;
  text-align: center;
  margin: 0 0 20px;
}
.project-list-mobile .card div {
  color: #fff;
  font-size: 0.7em;
  margin: 20px 0;
  line-height: 1.5em;
}
.project-list-mobile .card a {
  color: #FFFFFF;
  text-decoration: none !important;
  display: block;
  font-size: 0.9em;
  width: 100px;
  text-align: center;
  width: 160px;
  height: 160px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}

.project-list-mobile .card .card-bc {
  background-image: 
  url("../assets/bc.png");
  background-size: "cover";
  background-repeat: no-repeat;
  background-position: center center;
  height: 200px;
}

.project-details {
	display: grid;
	grid-template-columns: 3fr 2fr;
	align-items: start;
	grid-gap: 60px;
}

.btn-delete{
	background: #212121;
	padding: 8px 12px;
	border-radius: 15px;
	color: rgb(253, 2, 2);
	cursor: pointer;
	font-size: 1em;
	border: 1px solid rgb(253, 2, 2);
}
.btn-delete:hover {
	color: #fff;
	background-color: rgb(253, 2, 2);
}

.btn-cancel{
	background: #3d3d3d;
	margin: 0 12px;
	padding: 8px 12px;
	border-radius: 15px;
	color: rgb(253, 2, 2);
	cursor: pointer;
	font-size: 1em;
	border: 1px solid rgb(253, 2, 2);
}
.btn-cancel:hover {
	color: #fff;
	background-color: rgb(253, 2, 2);
}

.btn-register{
	background: #3d3d3d;
	padding: 8px 12px;
	border-radius: 15px;
	color: #3bdd92;
	cursor: pointer;
	font-size: 1em;
	border: 1px solid #3bdd92;
}
.btn-register:hover {
	color: #fff;
	background-color: #3bdd92;
}

.note {
	color: rgb(253, 2, 2);
}

.congrats-text {
	color: #fff;
}

.auth-form {
	max-width: 400px;
	margin: 60px auto;
	padding: 40px;
	border-radius: 20px;
	border: 1px solid #121212;
	box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
	background: #212121;
	color: #fff;
}

/* project summary */
.desktop .project-summary {
	background-color: #212121;
	border: 1px solid #121212;
	padding: 30px;
	border-radius: 20px;
	box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
	margin: -70px 0 0 0;
}

.mobile .project-summary {
	box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
	background-color: #212121;
	border: 1px solid #121212;
	padding: 30px;
	border-radius: 20px;
}


.project-summary .details {
	margin: 30px 0;
	color: var(--text-color);
	line-height: 1.8em;
	font-size: 0.9em;
}

.project-summary .info {
	margin: 10px 0;
	line-height: 1.8em;
	font-size: 1.2em;
	color: #fff;
}

.project-summary .info-rules {
	margin: 10px 0;
	line-height: 1.8em;
	font-size: 1em;
	color: #aaa;
}

.project-summary h4 {
	color: var(--text-color);
	font-size: 0.9em;
}
.project-summary + .btn-delete {
	margin-top: 20px;
}
.project-history ul {
	max-width: 80%;
}

.project-history h4 {
	color: var(--heading-color);
}

.project-history li {
	padding: 16px;
	border-radius: 20px;
	border: 1px solid #f2f2f2;
	margin-top: 10px;
	box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
	background: #fff;
	border-bottom: 4px solid #3bdd92;
}

.project-history li span {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50%;
	float: left;
	margin-right: 20px;
	
}
.badge {
	display: table-cell;
	
	
	font-size: 16px;
	color: #fff !important;
	text-align: center;
	white-space: nowrap;
	background-color: #3bdd92;
	border-radius:50px;
	position: relative;
	top: -3px;
}
.status-date {
	font-size: 0.9em;
	margin: 4px 0 10px;
}
.status {
	font-size: 0.9em;
}


.user-list {
	width: 250px;
	min-width: 250px;
	padding: 30px;
	box-sizing: border-box;
	background: #fbfbfb;
	color: var(--heading-color);
}
.user-list h2 {
	text-align: right;
	margin-bottom: 40px;
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
	font-size: 1.2em;
}

.scroll-down {
	text-align: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	font-size: 0.8em;
	color: #aeaeae;
}


.user-list .user-list-item {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 20px auto;
}

.radio-buttons-participate {
	display: inline;
	text-align: left;
	horizontal-align: left;
	align-items: left;
	width: auto;
}

.desktop .project-summary h3 {
	color: #fff;
	font-size: 1.7em !important;
	text-align: center;
	margin: 0 0 20px 0;

}

.desktop .project-summary h2 {
	color: #aaa;
	font-size: 1em !important;
	text-align: center;
	margin: 0 0 20px 0;
}

.mobile .project-summary h2 {
	color: #aaa;
	font-size: 0.8em !important;
	text-align: center;
	margin: 0 0 20px 0;
}


.project-summary .lime {
	color: #3bdd92;
}


.desktop .project-summary h4 {
	color: #3bdd92;
	font-size: 1.3em !important;
	text-align: center;
	margin: 0 0 20px 0;

}

.mobile .project-summary h4 {
	color: #3bdd92;
	font-size: 1em !important;
	text-align: center;
	margin: 0 0 20px 0;

}

.desktop .project-summary .deadline {
	color: #aaa;
	font-size: 1em !important;
	text-align: center;
	margin: 20px 0 50px 0;
}


.desktop .project-summary .card-bc {
	background-image: 
		url("../../assets/event.png");
	background-size: "cover";
	background-repeat: no-repeat;
	background-position: center center;
	height: 400px;
}

.desktop .project-summary .partner-bg {
	background-image: url("../../assets/partners2023.png");
	width: 1000px;
	height:600px;
	background-size: cover; /* or contain depending on what you want */
	background-position: center center;
	background-repeat: no-repeat;
	text-align:center;
	margin:auto;
	padding:0;
}

.mobile .project-summary .partner-bg {
	background-image: url("../../assets/partners2023.png");
	width: 300px;
	height: 200px;
	background-size: cover; /* or contain depending on what you want */
	background-position: center center;
	background-repeat: no-repeat;
	text-align:center;
	margin:auto;
	padding:0;
}

.mobile .project-summary h3 {
	color: #fff;
	font-size: 1em !important;
	text-align: center;
	margin: 0 0 20px 0;

}

.mobile .project-summary .deadline {
	color: #aaa;
	font-size: 1em !important;
	text-align: center;
	margin: 20px 0 10px 0;
}


.mobile .project-summary .card-bc {
	background-image: 
		url("../../assets/event_mobile.jpeg");
	background-size: "cover";
	background-repeat: no-repeat;
	background-position: center center;
	height: 90px;
}

.desktop input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.desktop input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    position: relative;
    background-color: #3bdd92;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}


input {
	font-size: 1em !important;
}

.desktop input[type='checkbox']:after {
    width: 15px;
    height: 15px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.desktop input[type='checkbox']:checked:after {
    width: 15px;
    height: 15px;
    position: relative;
    background-color: #3bdd92;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
	
}

.mobile input[type='checkbox'] {
	width: 15px;
    height: 15px;
    position: relative;
}

.mobile input[type='radio'] {
	width: 15px;
    height: 15px;
    position: relative;
}


input {
	font-size: 1em !important;
}

textarea {
	line-height: 1;
	font-size: 1.3em !important;
}


.desktop .privacy-container {
	color: white;
	padding: 0 20px;
}

.mobile .privacy-container {
	color: white;
	font-size: 10px;
}


.container {
	margin: 20px 0;
}

.privacy-container .container-fluid a {
	color: #3bdd92;
}

.privacy-container .container a {
	color: #3bdd92;
}

.privacy-container .container h2 {
	margin: 20px 0;
}



.privacy-container .container-fluid h2 {
	margin: 20px 0;
}

.navbar {
    width: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    margin-bottom: 80px;
  }
  .navbar ul {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar .logo {
    font-weight: bold;
    color: var(--heading-color);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .navbar .logo img {
    margin-right: 10px;
	height: 3em;
    margin-top: px;
  }

  .navbar .logo-no-text {
    font-weight: bold;
    color: var(--heading-color);
    letter-spacing: 1px;
    display: flex;
    margin-right: auto;
  }

  .navbar .logo-no-text img {
    width: 60px;
  }

  .navbar a {
    color: #3bdd92;
    text-decoration: none;
    margin-right: 20px;
  }

  .navbar a:hover {
    color:#3bdd92;
    text-decoration: none;
    margin-right: 20px;
  }
